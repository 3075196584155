import { ExpandLess, ExpandMore } from "components/LEGACY/styled-components/Icons"
import {
  CategoryArrowButton,
  CategoryContainer,
  CategoryDescription,
  CategoryHeader,
  CategoryTitle,
  CatMeta,
} from "components/LEGACY/styled-components/Storefront"
import BundleCard from "components/orderPage/BundleCard"
import Productgroup from "components/orderPage/CategoryDisplay/Productgroup"
import { useCategoryVisibleProductGroups } from "hooks/firestore/advanced/useCategoryVisibleProductGroups"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { useCategory } from "hooks/firestore/simple/useCategory"
import { useUserId } from "hooks/localstate/auth/useUserId"
import { useAdminStorefront } from "hooks/localstate/url/useAdminStorefront"
import { useB2BStorefront } from "hooks/localstate/url/useB2BStorefront"
import useInView from "hooks/misc/useInVIew"
import truncate from "lodash/truncate"
import React, { useState } from "react"
import ProductGroup from "types/firestore/productGroup"

interface ProductGroupCategoryProps {
  categoryId: string
}

const ProductGroupCategory = ({ categoryId }: ProductGroupCategoryProps) => {
  const userId = useUserId()
  const { ref, inView } = useInView({
    rootMargin: "0px",
    root: null,
    threshold: 0,
  })
  const category = useCategory(categoryId)
  const productGroups = useCategoryVisibleProductGroups(inView ? categoryId : undefined, userId)
  const [foldOpen, setFoldOpen] = useState(true)
  const catDesc =
    category &&
    truncate(category.description || "", {
      length: foldOpen ? 20000 : 100,
    })

  const expectedNumberOfProductGroups = category?.visibleProductGroupCount ?? category?.productGroups?.length ?? 0
  const renderedProductGroups: (WithRef<ProductGroup> | undefined)[] | undefined =
    (productGroups?.length && productGroups) || Array<undefined>(expectedNumberOfProductGroups).fill(undefined)

  const isB2BStorefront = useB2BStorefront()
  const isAdminStorefront = useAdminStorefront()

  return category?.visible &&
    category.parentVisible &&
    (isAdminStorefront || isB2BStorefront || !category.isB2BOnly) ? (
    <CategoryContainer ref={ref}>
      <CategoryHeader onClick={() => setFoldOpen(!foldOpen)}>
        <CategoryArrowButton>
          {foldOpen ? (
            <ExpandLess color={"currentColor"} size="22.4" />
          ) : (
            <ExpandMore color={"currentColor"} size="22.4" />
          )}
        </CategoryArrowButton>
        <CatMeta>
          <CategoryTitle>{category?.name}</CategoryTitle>
          {catDesc && <CategoryDescription>{catDesc}</CategoryDescription>}
        </CatMeta>
      </CategoryHeader>
      {foldOpen && renderedProductGroups?.map((product, index) => <Productgroup key={index} product={product} />)}
      {foldOpen &&
        category.bundles &&
        category.bundles.map((b, index) => <BundleCard key={`bundle-${index}`} bundleId={b} />)}
    </CategoryContainer>
  ) : null
}

export default React.memo(ProductGroupCategory)
