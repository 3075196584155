import { getAnalytics, setCurrentScreen } from "@firebase/analytics"
import MainView from "components/orderPage/MainView"
import ShopUserManager from "components/orderPage/ShopUserManager"
import Theme from "components/styles/Theme"
import CommonHead from "components/utils/CommonHead"
import ShopIdContextProvider from "context/orderFormContexts/ShopIdContext"
import { useDocument } from "hooks/firestore/core/useDocument"
import { getShopRef } from "hooks/firestore/getRefs"
import { useUser } from "hooks/firestore/simple/useUser"
import { useHost } from "hooks/misc/useHost"
import md5 from "md5"
import Head from "next/head"
import LoginPage from "pages/login"
import { useEffect, useState } from "react"
import Shop from "types/firestore/shop"

interface NextOrderPageProps {
  shopId: string
}

const NextOrderPage = ({ shopId }: NextOrderPageProps) => {
  // return <span>{shopId}</span>
  const { document: shop } = useDocument<Shop>(shopId && getShopRef(shopId))
  const user = useUser()
  const { origin } = useHost()
  const [pixelLoaded, setPixelLoaded] = useState(false)
  useEffect(() => {
    if (shop?.name) {
      setCurrentScreen(getAnalytics(), shop?.name)
    }
  }, [shop?.name])

  const loadPixel = () => {
    if (shop?.fbPixelId && !pixelLoaded && Cookiebot?.consent?.marketing) {
      import("react-facebook-pixel")
        .then(module => module.default)
        .then(ReactPixel => {
          ReactPixel.init(shop?.fbPixelId || "")

          ReactPixel.pageView()
          setPixelLoaded(true)
        })
    }
  }

  useEffect(() => {
    if (shop?.fbPixelId) {
      window.addEventListener("CookiebotOnAccept", () => {
        if (Cookiebot?.consent?.marketing) {
          loadPixel()
        }
      })
    }
  }, [shop?.fbPixelId])

  // Generate a consistent random description for google
  const shopHash = md5(shopId, { asBytes: true })
  const where =
    shopHash[0] < 63
      ? "auf deinem Smartphone oder Computer"
      : shopHash[0] < 127
        ? "von deinem Smartphone aus"
        : shopHash[0] < 190
          ? "in unserem Onlineshop"
          : "von Zuhause"
  const partOne = shopHash[1] < 127 ? "Schau dich um" : "Sieh dich um"
  const partTwo = shopHash[2] < 127 ? "lass dich inspirieren" : "entdecke unsere Auswahl"
  const sentenceOne = `${partOne}, ${partTwo} und bestell dann ganz bequem ${where}.`
  const assortment =
    shopHash[3] < 90 ? "unser gesamtes Sortiment" : shopHash[3] < 180 ? "alle unsere Artikel" : "unsere Produkte"
  const hereOnline = shopHash[4] < 127 ? "hier online" : "im Webshop"
  const assortmentLine = shopHash[5] < 127 ? `${assortment} ${hereOnline}` : `${hereOnline} ${assortment}`
  const sentenceTwo =
    shopHash[6] < 90
      ? `Wir bieten ${assortmentLine} an.`
      : shopHash[6] < 180
        ? `Du kannst ${assortmentLine} bestellen.`
        : `Wir haben ${assortmentLine} im Angebot.`
  const defaultDescription = shopHash[7] < 127 ? `${sentenceTwo} ${sentenceOne}` : `${sentenceOne} ${sentenceTwo}`

  //Only to get the context on highest level
  return shop ? (
    <>
      <CommonHead
        title={`${shop?.name || "getpacked"} - Jetzt online bestellen`}
        description={shop?.description || shop?.customShopNotification || defaultDescription}
        image={
          shop.openGraphImage
            ? shop.openGraphImage.url
            : shop?.image && (typeof shop.image === "string" ? shop.image : shop.image?.url)
        }
        url={`${origin}/${shop?.shorthand}`}
      />
      <Head>
        <script
          key="local-business"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": shop?.type
                ? {
                    bakery: "Bakery",
                    farmersmarket: "GroceryStore",
                    restaurant: "Restaurant",
                    shop: "Store",
                    butcher: "GroceryStore",
                    others: "Store",
                  }[shop.type] ?? "Store"
                : "Store",
              ...(shop?.description ? { description: shop?.description } : {}),
              ...(shop?.contact?.companyName ? { legalName: shop?.contact?.companyName } : {}),
              "name": shop?.name || shop?.contact?.companyName,
              "address": {
                "@type": "PostalAddress",
                ...(shop?.contact?.address ? { streetAddress: shop?.contact?.address } : {}),
                ...(shop?.contact?.city ? { addressLocality: shop?.contact?.city } : {}),
                ...(shop?.contact?.zipCode ? { postalCode: shop?.contact?.zipCode } : {}),
                "addressCountry": shop?.contact?.country || "DE",
              },
              "currenciesAccepted": "EUR",
              "paymentAccepted": shop?.payment_options
                ?.filter(option => option.active)
                .map(option =>
                  option.value === "cash"
                    ? ["Cash"]
                    : option.value === "card"
                      ? ["Credit Card", "Stripe"]
                      : option.value === "paypal"
                        ? ["PayPal"]
                        : []
                )
                .join(", "),
              "telephone": shop?.contact?.phone,
              "email": shop?.contact?.email,
              "url": shop?.contact?.website ?? `${origin}/${shop?.shorthand}`,
              "menu": `${origin}/${shop?.shorthand}`,
              "logo": typeof shop?.image === "string" ? shop.image : shop?.image?.url,
            }),
          }}
        />
      </Head>
      <Theme uiColor={shop?.uiColor} />
      <ShopIdContextProvider shopId={shopId}>
        {/* If the shop requires login, we need to check if the user is logged in */}
        {shop.featureLoginRequired ? (
          user ? (
            <ShopUserManager>
              <MainView />
            </ShopUserManager>
          ) : (
            <LoginPage />
          )
        ) : (
          <MainView />
        )}
      </ShopIdContextProvider>
    </>
  ) : null
}

export default NextOrderPage
