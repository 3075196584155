import { Button } from "components/LEGACY/styled-components/Buttons"
import Input from "components/utils/InputField"
import { setDoc } from "firebase/firestore"
import { getShopUserRef } from "hooks/firestore/getRefs"
import { useShop } from "hooks/firestore/simple/useShop"
import { useShopUser } from "hooks/firestore/simple/useShopUser"
import { useCurrentUser } from "hooks/localstate/auth/useCurrentUser"
import { useShopId } from "hooks/localstate/context/useShopId"
import { useDisplayToast } from "hooks/misc/useDisplayToast"
import { useState } from "react"
import { Timestamp } from "sharedInterface"
import { BillingAddress, ShopUser } from "types/firestore/shopUser"

/**
 * This component wraps the main view
 * if the user is logged in and the shop requires login.
 *
 * It checks if the user has a doc in the shop's users collection.
 * If it is, it returns the children.
 * If their is not doc in the collection for the user, it shows a "Continue" button that adds the user to the collection.
 */
export default function ShopUserManager({ children }: { children: React.ReactNode }) {
  const shopId = useShopId()
  const shop = useShop()
  const shopUser = useShopUser()
  const user = useCurrentUser()
  const [isLoading, setIsLoading] = useState(false)
  const toast = useDisplayToast()
  const [billingAddress, setBillingAdress] = useState<BillingAddress>({
    name: "",
    street: "",
    city: "",
    number: "",
    zipCode: "",
    country: "Deutschland",
  })

  const isBillingAddressValid =
    !!billingAddress.name &&
    !!billingAddress.street &&
    !!billingAddress.city &&
    !!billingAddress.number &&
    !!billingAddress.zipCode

  const handleSetShopUser = (address: BillingAddress) => {
    try {
      setIsLoading(true)
      const ref = getShopUserRef(shopId, user?.uid)!

      setDoc(ref, {
        email: user?.email,
        joinedAt: Timestamp.now(),
        confirmedAt: null,
        billingAddress: address,
      } as ShopUser)
    } catch (e) {
      toast("Da ist etwas schiefgelaufen!", "error")
    } finally {
      setIsLoading(false)
    }
  }

  // User does not exist in shop
  if (!shopUser)
    return (
      <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-screen ">
        <form className="tw-grid tw-grid-cols-2 tw-gap-4">
          <Input
            className="tw-col-span-2"
            value={billingAddress.name}
            onChange={e =>
              setBillingAdress(prev => ({
                ...prev,
                name: e.target.value,
              }))
            }
          >
            Name
          </Input>
          <Input
            className="tw-col-span-1"
            value={billingAddress.street}
            onChange={e =>
              setBillingAdress(prev => ({
                ...prev,
                street: e.target.value,
              }))
            }
          >
            Straße
          </Input>
          <Input
            className="tw-col-span-1"
            value={billingAddress.number}
            onChange={e =>
              setBillingAdress(prev => ({
                ...prev,
                number: e.target.value,
              }))
            }
          >
            Hausnummer
          </Input>
          <Input
            className="tw-col-span-1"
            value={billingAddress.zipCode}
            onChange={e =>
              setBillingAdress(prev => ({
                ...prev,
                zipCode: e.target.value,
              }))
            }
          >
            Postleitzahl
          </Input>
          <Input
            className="tw-col-span-1"
            value={billingAddress.city}
            onChange={e =>
              setBillingAdress(prev => ({
                ...prev,
                city: e.target.value,
              }))
            }
          >
            Stadt
          </Input>
          <Input
            className="tw-col-span-2"
            value={billingAddress.country}
            onChange={e =>
              setBillingAdress(prev => ({
                ...prev,
                country: e.target.value,
              }))
            }
          >
            Land
          </Input>
        </form>
        <Button
          className="tw-mt-8"
          disabled={isLoading || !isBillingAddressValid}
          onClick={() => handleSetShopUser(billingAddress)}
        >
          {isLoading ? "Zugriff wird angefragt" : `Zugriff zu ${shop?.name} anfragen`}
        </Button>
      </div>
    )

  // User exists in shop but is not confirmed by external system like RIWO for Lindchen
  if (!shopUser.confirmedAt) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
        <p className="tw-text-center tw-text-lg tw-font-bold tw-max-w-md tw-text-gray-500">
          Warte auf Bestätigung durch {shop?.name}. Sobald du bestätigt bist, kannst du bei {shop?.name} bestellen.
        </p>
      </div>
    )
  }

  // User exists in shop and is confirmed by external system
  return <>{children}</>
}
