import { getDocument, useDocument } from "hooks/firestore/core/useDocument"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { getShopRef, getShopUserRef } from "hooks/firestore/getRefs"
import { useUserId } from "hooks/localstate/auth/useUserId"
import { useShopId } from "hooks/localstate/context/useShopId"
import { ShopUser } from "types/firestore/shopUser"

// ts-prune-ignore-next
export function useShopUser(): WithRef<ShopUser> | undefined {
  const shopId = useShopId()
  const userId = useUserId()
  const { document } = useDocument(getShopUserRef(shopId, userId))
  return document
}

// ts-prune-ignore-next
export async function getShop(shopId: string) {
  return await getDocument(getShopRef(shopId))
}
